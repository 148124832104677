<template>
  <div class="personalBox">
    <div class="headerBox">
      <div class="headerIcon"></div>
      <div>
        <div>
          <TextBox :font-size="36" color="textColor1" @click.native="judgeLogin">{{
            $formatPhone(userInfo.phone)
          }}</TextBox>
          <img :src="vipList[userInfo.level]" alt="" class="vip" v-show="$isLogin()" />
          <img src="@/assets/images/personal/a7.png" alt="" class="tips" v-show="$isLogin()" @click="showVipTips" />
        </div>
        <div>
          <span>UID：{{ userInfo.uid }}</span>
          <span>自购 {{ `${userInfo.selfPower}/${userInfo.selfPowerUpgrade}` }}</span>
          <span>推广 {{ `${userInfo.totalPower}/${userInfo.totalPowerUpgrade}` }}</span>
        </div>
      </div>
    </div>
    <div class="contentBox">
      <div class="switchBox radius bgWhite">
        <Space :height="30"></Space>
        <van-tabs type="card" v-model="classVip" @click="classSwitchClick">
          <van-tab title="一期"></van-tab>
          <van-tab title="二期"></van-tab>
        </van-tabs>
        <Space :height="32"></Space>
        <div class="df aife">
          <div>
            <PriceUnit :value="userInfo.effectivePower-userInfo.redeemPower" :font-size="48" unit="T" class="contentBox"></PriceUnit>
            <Space :height="10"></Space>
            <div class="df aic contentBox">
              <TextBox color="intro" :font-size="24">
                有效算力
              </TextBox>
              <Space :width="10"></Space>
              <div class="switchContentTipsBox">我的总存储：{{ userInfo.sumPower }} T</div>
              <div class="addWrap" @click="onAddPower" v-if="isShowAddPower">购买加速包</div>
            </div>
          </div>
        </div>
        <Space :height="30"></Space>
        <div class="df aic contentBox"  v-if="userInfo.redeemPower>0">
          <div class="switchContentTipsBoxRedeemPower" >赎回算力：{{ userInfo.redeemPower }} T</div>
          <Space :width="10"></Space>
          <div class="switchContentTipsBoxRedeemFil">赎回FIL：{{ userInfo.redeemFil }} FIL</div>
        </div>
        <van-divider />
        <Space :height="30"></Space>
        <div class="df aife">
          <div>
            <PriceUnit :value="userInfo.getCoin" :font-size="48" unit="FIL" class="contentBox"></PriceUnit>
            <Space :height="10"></Space>
            <div class="df aic contentBox">
              <TextBox :font-size="24" color="intro">累计收益</TextBox>
              <img
                src="@/assets/images/personal/a7.png"
                alt=""
                class="ml8"
                :width="$pxToPxRatio(22)"
                :height="$pxToPxRatio(22)"
                @click="showEarningsTips"
              />
              <!-- <Space :width="10"></Space> -->
              <!-- <div class="switchContentTipsBox">昨日收益：{{ userInfo.yesterCoin }} FIL</div> -->
            </div>
          </div>
        </div>
        <Space :height="30"></Space>
        <!-- <van-divider />
        <Space :height="30"></Space>
        <div class="df aife">
          <div>
            <PriceUnit :value="userInfo.arrearageAmt" :font-size="48" unit="FIL" class="contentBox"></PriceUnit>
            <Space :height="10"></Space>
            <div class="df aic contentBox">
              <TextBox :font-size="24" color="intro">待结托管费</TextBox>
              <img
                src="@/assets/images/personal/a7.png"
                alt=""
                class="ml8"
                :width="$pxToPxRatio(22)"
                :height="$pxToPxRatio(22)"
                @click="showCustodyTips"
              />
            </div>
          </div>
        </div>
        <Space :height="30"></Space> -->
      </div>
      <Space :height="20"></Space>
      <div v-for="(item, index) of listBox" :key="index">
        <ListBox :icon="item.icon" :title="item.title" :right-icon="true" @click.native="goSetting(item.url)"></ListBox>
      </div>
      <Space :height="62"></Space>
    </div>
    <TabBar :active="2"></TabBar>
    <DialogBox ref="vipDialogBox" :title="upgradeInfo.title" :width="568">
      <div class="dialogContext">
        <div class="dialogList" v-for="(item, index) of upgradeInfo.value" :key="index">{{ item }}</div>
        <div class="closeBox"></div>
      </div>
    </DialogBox>
    <DialogBox ref="earningsDialogBox" title="累计收益说明" :width="568">
      <div class="dialogContext">
        <div class="dialogList" style="text-align: center;">
          累计收益为{{ classVip === 0 ? '一期' : '二期' }}节点总产出量与奖池奖励之和
        </div>
        <div class="closeBox"></div>
      </div>
    </DialogBox>
    <DialogBox ref="CustodyDialogBox" title="待结托管费说明" :width="568">
      <div class="dialogContext">
        <div class="dialogList" style="text-align: center;">
          托管费按日收取，为“0”表示托管费已缴清，大于“0”表示欠托管费。
        </div>
        <div class="closeBox"></div>
      </div>
    </DialogBox>
  </div>
</template>
<script>
export default {
  name: '',
  // mixins: [],
  // components: {},
  // props: [],
  data() {
    return {
      isShowAddPower: false,
      // 一期 0  二期 1
      classVip: 0,
      upgradeInfo: {
        title: '一期用户晋升规则',
        value: [
          '普通用户：自购0台  推荐0台',
          'V1用户：自购3台 推荐20台',
          'V2用户：自购5台 推荐100台',
          'V3用户：自购10台 推荐200台',
        ],
      },
      vipList: [
        require('@/assets/images/personal/a6.png'),
        require('@/assets/images/personal/a4.png'),
        require('@/assets/images/personal/a2.png'),
        require('@/assets/images/personal/a1.png'),
      ],
      listBox: [
        {
          icon: require('@/assets/images/personal/b5.png'),
          title: '我的积分',
          url: '/personal/myAssets',
        },
        {
          icon: require('@/assets/images/personal/b4.png'),
          title: '我的订单',
          url: '/locked/order',
        },
        {
          icon: require('@/assets/images/personal/b1.png'),
          title: '收益明细',
          url: '/personal/moneyHistory',
        },
        // {
        //   icon: require('@/assets/images/personal/b6.png'),
        //   title: '邀请分享',
        //   url: '/personal/invite',
        // },
        {
          icon: require('@/assets/images/personal/b2.png'),
          title: '账号管理',
          url: '/personal/admin',
        },
        {
          icon: require('@/assets/images/personal/b3.png'),
          title: '设置',
          url: '/personal/set',
        },
      ],
      userInfo: {
        uid: '******',
        phone: '请登录',
        level: 0,
        // 推广总算力
        totalPower: 0,
        selfPower: 0,
        totalPowerUpgrade: 0,
        selfPowerUpgrade: 0,
        // 总算里
        sumPower: 0,
        // 有效算力
        effectivePower: 0,
        getCoin: 0,
      },
    };
  },
  created() {
    this.classVip = this.$store.state.personal.userActive;
    this.getAddPowerStatus();
  },
  // mounted () {},
  methods: {
    getAddPowerStatus() {
      this.$http('get', '/v1/accelerate/accelerateDdetails', {
        goodsId: this.classVip + 1,
      }).then(res => {
        if (res.data) {
          this.isShowAddPower = true;
        } else {
          this.isShowAddPower = false;
        }
      });
    },
    onAddPower() {
      this.$router.push('/personal/addPower');
    },
    judgeLogin() {
      if (!this.$$localStorage.getItem('token')) {
        this.$router.push({
          path: '/account/login',
          query: {
            redirect: this.$route.path,
          },
        });
      }
    },
    getUserInfo() {
      if (!this.$isLogin()) return;
      this.$http('get', '/v1/user/userInfo', {
        goodsId: this.classVip + 1,
      }).then(res => {
        this.userInfo = res.data;
      });
    },
    getUpgradeInfo() {
      this.$http('get', '/v1/netdata/reward/info', {
        goodsId: this.classVip + 1,
      }).then(res => {
        let levelProcess = res => {
          switch (res) {
            case 0:
              return '普通';
            case 1:
              return 'v1';
            case 2:
              return 'v2';
            case 3:
              return 'v3';
          }
        };
        let list = res.data.sort((a, b) => a.level - b.level);
        let unit = this.classVip === 0 ? '台' : 'T';
        this.upgradeInfo = {
          title: `${this.classVip === 0 ? '一' : '二'}期用户晋升规则`,
          value: [`普通用户：自购0${unit}  推荐0${unit}`].concat(
            list.map(item => {
              return `${levelProcess(item.level)}用户：自购${item.selfPower}${unit}  推荐${item.power}${unit}`;
            }),
          ),
        };
      });
    },
    showVipTips() {
      this.$refs.vipDialogBox.init(true);
    },
    showEarningsTips() {
      this.$refs.earningsDialogBox.init(true);
    },
    showCustodyTips() {
      this.$refs.CustodyDialogBox.init(true);
    },
    goSetting(res) {
      this.$router.push(res);
    },
    classSwitchClick() {
      this.$store.commit('personal/save', {
        userActive: this.classVip,
      });
    },
  },
  watch: {
    classVip: {
      handler(res) {
        this.getUserInfo();
        this.getUpgradeInfo();
      },
      immediate: true,
    },
  },
  computed: {},
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less">
.personalBox {
  .headerBox {
    width: 750px;
    height: 367px;
    background: url('~@/assets/images/personal/a5.png') no-repeat center;
    background-size: cover;
    padding: 124px 30px 123px;
    display: flex;
    .headerIcon {
      width: 120px;
      height: 120px;
      margin-right: 30px;
      border-radius: 50%;
      background: url('~@/assets/images/personal/a3.png') no-repeat center;
      background-size: cover;
      &.bgWhite {
        background: @white;
      }
    }
    > div {
      display: flex;
      justify-content: center;
      flex-direction: column;
      > div {
        display: flex;
        align-items: center;
        &:last-child {
          margin-top: 15px;
        }
        span {
          font-size: 24px;
          color: @text-color1;
          margin-right: 30px;
        }
        .vip {
          width: 63px;
          height: 30px;
          margin-left: 10px;
        }
        .tips {
          width: 22px;
          height: 22px;
          margin-left: 14px;
          margin-top: 10px;
        }
      }
    }
  }
  > .contentBox {
    margin-top: -89px;
    .switchBox {
      box-shadow: 0px 2px 21px 2px rgba(212, 212, 239, 0.39);
      position: relative;
    }
  }
}
.dialogContext {
  padding: 23px 40px;
  position: relative;
  .dialogList {
    padding-left: 67px;
    font-size: 26px;
    color: #0e0d51;
    line-height: 62px;
  }
  .closeBox {
    width: 48px;
    height: 82px;
    position: absolute;
    bottom: -82px;
    left: 50%;
    background: url('~@/assets/images/personal/close.png') no-repeat center;
    background-size: contain;
    transform: translate(-50%);
  }
}
/deep/ .dialog {
  transform: translateY(-42px);
}
.switchContentTipsBox {
  background: #fff6e9;
  border-radius: 8px 8px 8px 0px;
  padding: 2px 9px;

  font-size: 24px;
  font-weight: 400;
  color: #e28710;
  line-height: 33px;
}
.switchContentTipsBoxRedeemPower {
  background: #ECF0FF;
  border-radius: 8px 8px 8px 0px;
  padding: 2px 9px;

  font-size: 24px;
  font-weight: 400;
  color: #525298 ;
  line-height: 33px;
}
.switchContentTipsBoxRedeemFil {
  background: #EFFBF5;
  border-radius: 8px 8px 8px 0px;
  padding: 2px 9px;

  font-size: 24px;
  font-weight: 400;
  color: #22BC6F;
  line-height: 33px;
}
.addWrap {
  background: #e28710;
  border-radius: 8px;
  margin-left: 10px;

  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
  line-height: 33px;
  padding: 2px 11px;
}
</style>
